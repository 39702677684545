<template>
  <section
    id="dashboard-analytics"
    data-app
  >
    <b-row
      v-if="activeProjectId != null"
      class="match-height"
    >
      <transition
        mode="out-in"
        name="fade"
      >
        <b-col
          v-show="emptyStats !== 'NO_STATS'"
          cols="12"
        >
          <div>
            <b-col
              cols="12"
              style="padding-left:0"
            >
              <b-form-row>
                <b-col
                  class="mb-1"
                  cols="8"
                  lg="6"
                  md="4"
                >
                  <b-form-checkbox-group
                    v-model="selectedGender"
                    :options="optionsGenders"
                    name="buttons-1"
                    buttons
                    button-variant="warning"
                  />
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col
                  class="mb-1"
                  cols="4"
                  lg="6"
                  md="4"
                >
                  <b-form-checkbox-group
                    v-model="selectedAge"
                    :options="optionsAges"
                    name="buttons-1"
                    buttons
                    button-variant="secondary"
                  />
                </b-col>
              </b-form-row>
            </b-col>
            <b-link
              v-b-modal.modal-xl
              style="text-decoration: dotted underline;
              text-underline-offset: 3px;"
            >
              Показать доступные столбцы
            </b-link>
            <b-modal
              id="modal-xl"
              centered
              ok-only
              ok-title="Закрыть"
              size="xl"
              title="Выбор столбцов"
            >
              <section>
                <b-col cols="12">
                  <b-field
                    class="mb-2 mt-1"
                  >
                    <b-row>
                      <b-col
                        v-for="(type, type_index) in groupedColumns"
                        :key="type_index"
                      >
                        <h4
                          class="mb-1"
                        >
                          {{ type_index }}
                          <span v-if="!isDisabledColumns(type_index)">
                            <b-badge
                              style="font-size: 60%;"
                              variant="danger"
                            >
                              не подключено
                            </b-badge>
                          </span>
                        </h4>
                        <div
                          v-for="(column, index) in type"
                          :key="index"
                          class="control mb-1"
                        >
                          <b-checkbox
                            v-model="column.display"
                            :disabled="!isDisabledColumns(type_index)"
                          >
                            {{ column.title }}
                          </b-checkbox>
                        </div>
                      </b-col>
                    </b-row>
                  </b-field>
                </b-col>
              </section>
            </b-modal>

            <b-table
              ref="table"
              :data="data"
              :loading="isLoading"
              :paginated="true"
              :per-page="perPage.value"
              :show-detail-icon="showDetailIcon"
              :sticky-header="true"
              class="mt-2"
              custom-detail-row
              detail-key="id"
              detailed
              height="600px"
              hoverable
            >
              <!-- campaign -->
              <b-table-column
                v-slot="props"
                :label="columnsVisible['campaign'].title"
                field="campaign"
                sortable
                width="300"
              >
                <template v-if="showDetailIcon">
                  {{ props.row.campaign }}
                </template>
                <template v-else>
                  <a @click="toggle(props.row)">
                    {{ props.row.campaign }}
                  </a>
                </template>
              </b-table-column>

              <!-- shows -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'shows')"
                :label="columnsVisible['shows'].title"
                :visible="columnsVisible['shows'].display"
                centered
                field="shows"
                sortable
              >
                {{ columnValueFormat(props.row.shows) }}
              </b-table-column>

              <!-- clicks -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'clicks')"
                :label="columnsVisible['clicks'].title"
                :visible="columnsVisible['clicks'].display"
                centered
                field="clicks"
                sortable
              >
                {{ columnValueFormat(props.row.clicks) }}
              </b-table-column>

              <!-- consumption -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumption')"
                :label="columnsVisible['consumption'].title"
                :visible="columnsVisible['consumption'].display"
                centered
                field="consumption"
                sortable
              >
                {{ columnValueFormat(props.row.consumption) }}
              </b-table-column>

              <!-- avgCpc -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'avgCpc')"
                :label="columnsVisible['avgCpc'].title"
                :visible="columnsVisible['avgCpc'].display"
                centered
                field="avgCpc"
                sortable
              >
                {{ columnValueFormat(props.row.avgCpc) }}
              </b-table-column>

              <!-- ctr -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'ctr')"
                :label="columnsVisible['ctr'].title"
                :visible="columnsVisible['ctr'].display"
                centered
                field="ctr"
                sortable
              >
                {{ columnValueFormat(props.row.ctr) }}
              </b-table-column>

              <!-- AvgTrafficVolume -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'ctr')"
                :label="columnsVisible['avgTrafficVolume'].title"
                :visible="columnsVisible['avgTrafficVolume'].display"
                centered
                field="avgTrafficVolume"
                sortable
              >
                {{ columnValueFormat(props.row.avgTrafficVolume) }}
              </b-table-column>

              <!-- Analytic block start -->
              <!-- analyticRevenue -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticRevenue')"
                :label="columnsVisible['analyticRevenue'].title"
                :visible="columnsVisible['analyticRevenue'].display"
                centered
                field="analyticRevenue"
                sortable
              >
                {{ columnValueFormat(props.row.analyticRevenue) }}
              </b-table-column>

              <!-- analyticMarginProfit -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticMarginProfit')"
                :label="columnsVisible['analyticMarginProfit'].title"
                :visible="columnsVisible['analyticMarginProfit'].display"
                centered
                field="analyticMarginProfit"
                sortable
              >
                {{ columnValueFormat(props.row.analyticMarginProfit) }}
              </b-table-column>

              <!-- analyticGoals -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticGoals')"
                :label="columnsVisible['analyticGoals'].title"
                :visible="columnsVisible['analyticGoals'].display"
                centered
                field="analyticGoals"
                sortable
              >
                {{ columnValueFormat(props.row.analyticGoals) }}
              </b-table-column>

              <!-- analyticCr -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticCr')"
                :label="columnsVisible['analyticCr'].title"
                :visible="columnsVisible['analyticCr'].display"
                centered
                field="analyticCr"
                sortable
              >
                {{ columnValueFormat(props.row.analyticCr) }}
              </b-table-column>

              <!-- analyticCost -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticCost')"
                :label="columnsVisible['analyticCost'].title"
                :visible="columnsVisible['analyticCost'].display"
                centered
                field="analyticCost"
                sortable
              >
                {{ columnValueFormat(props.row.analyticCost) }}
              </b-table-column>

              <!-- analyticRoi -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticRoi')"
                :label="columnsVisible['analyticRoi'].title"
                :visible="columnsVisible['analyticRoi'].display"
                centered
                field="analyticRoi"
                sortable
              >
                {{ columnValueFormat(props.row.analyticRoi) }}
              </b-table-column>

              <!-- Analytic block end -->

              <!-- CRM block start -->
              <!-- profit -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'profit')"
                :label="columnsVisible['profit'].title"
                :visible="columnsVisible['profit'].display"
                centered
                field="profit"
                sortable
              >
                {{ columnValueFormat(props.row.profit) }}
              </b-table-column>

              <!-- marginProfit -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'marginProfit')"
                :label="columnsVisible['marginProfit'].title"
                :visible="columnsVisible['marginProfit'].display"
                centered
                field="profit"
                sortable
              >
                {{ columnValueFormat(props.row.marginProfit) }}
              </b-table-column>

              <!-- numberOrder -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'numberOrders')"
                :label="columnsVisible['numberOrders'].title"
                :visible="columnsVisible['numberOrders'].display"
                centered
                field="numberOrders"
                sortable
              >
                {{ columnValueFormat(props.row.numberOrders) }}
              </b-table-column>

              <!-- cr -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'cr')"
                :label="columnsVisible['cr'].title"
                :visible="columnsVisible['cr'].display"
                centered
                field="cr"
                sortable
              >
                {{ columnValueFormat(props.row.cr) }}
              </b-table-column>

              <!-- cost -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'cost')"
                :label="columnsVisible['cost'].title"
                :visible="columnsVisible['cost'].display"
                centered
                field="cost"
                sortable
              >
                {{ columnValueFormat(props.row.cost) }}
              </b-table-column>

              <!-- roi -->
              <b-table-column
                v-slot="props"
                :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'roi')"
                :label="columnsVisible['roi'].title"
                :visible="columnsVisible['roi'].display"
                centered
                field="roi"
                sortable
              >
                {{ columnValueFormat(props.row.roi) }}
              </b-table-column>
              <!-- CRM block end -->

              <template
                slot="detail"
                slot-scope="props"
              >

                <tr
                  v-for="item in props.row.items"
                  :key="item.id"
                >
                  <td v-if="showDetailIcon" />
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ item.criterion }}
                  </td>
                  <td
                    v-show="columnsVisible['shows'].display"
                    class="has-text-centered"
                  >
                    {{ item.shows }}
                  </td>
                  <td
                    v-show="columnsVisible['clicks'].display"
                    class="has-text-centered"
                  >
                    {{ item.clicks }}
                  </td>
                  <td
                    v-show="columnsVisible['consumption'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.consumption) }}
                  </td>
                  <td
                    v-show="columnsVisible['avgCpc'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.avgCpc) }}
                  </td>
                  <td
                    v-show="columnsVisible['ctr'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.ctr) }}
                  </td>
                  <td
                    v-show="columnsVisible['avgTrafficVolume'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.avgTrafficVolume) }}
                  </td>
                  <td
                    v-show="columnsVisible['analyticRevenue'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticRevenue) }}
                  </td>
                  <td
                    v-show="columnsVisible['analyticMarginProfit'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticMarginProfit) }}
                  </td>
                  <td
                    v-show="columnsVisible['analyticGoals'].display"
                    class="has-text-centered"
                  >
                    {{ item.analyticGoals }}
                  </td>
                  <td
                    v-show="columnsVisible['analyticCr'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticCr) }}
                  </td>
                  <td
                    v-show="columnsVisible['analyticCost'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticCost) }}
                  </td>
                  <td
                    v-show="columnsVisible['analyticRoi'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticRoi) }}
                  </td>
                  <td
                    v-show="columnsVisible['profit'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.profit) }}
                  </td>
                  <td
                    v-show="columnsVisible['marginProfit'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.marginProfit) }}
                  </td>
                  <td
                    v-show="columnsVisible['numberOrders'].display"
                    class="has-text-centered"
                  >
                    {{ item.numberOrders }}
                  </td>
                  <td
                    v-show="columnsVisible['cr'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.cr) }}
                  </td>
                  <td
                    v-show="columnsVisible['cost'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.cost) }}
                  </td>
                  <td
                    v-show="columnsVisible['roi'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.roi) }}
                  </td>
                </tr>
              </template>
            </b-table>
          </div>
        </b-col>
      </transition>

      <transition
        mode="out-in"
        name="fade"
      >
        <b-col
          v-show="emptyStats === 'NO_STATS'"
          cols="12"
        >
          <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
              <h2 class="mb-1">
                На проекте нет статистики
              </h2>
              <p class="mb-3">
                Подключите интеграции к вашему проекту
              </p>

              <b-img
                :src="downImg"
                alt=""
                fluid
              />
            </div>
          </div>
        </b-col>
      </transition>
    </b-row>
    <b-row
      v-if="activeProjectId == null"
      class="match-height"
    >

      <b-col cols="12">
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              Выберите проект или создайте новый
            </h2>
            <h4 class="mb-3">
              <b-link @click="tourStartAnalytics">
                Как это сделать?
              </b-link>
            </h4>
            <b-img
              :src="downImg"
              alt=""
              fluid
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <app-tour
      :steps="steps"
      name="test"
    />
  </section>
</template>
<script>
import _ from 'lodash'
import { kFormatter } from '@core/utils/filter'
import AppTour from '@core/components/app-tour/AppTour.vue'
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormRow,
  BImg,
  BLink,
  BOverlay,
  BRow,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import Ripple from 'vue-ripple-directive'
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData'

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))

// eslint-disable-next-line no-extend-native
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1
  const dd = this.getDate()

  // eslint-disable-next-line radix
  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('')
}

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series10: '#a9390c',
    series9: '#316b6b',
    series8: '#e26572',
    series7: '#e369b6',
    series6: '#5dd2db',
    series5: '#7253d0',
    series4: '#4f82cf',
    series3: '#8BC554',
    series2: '#FECC35',
    series1: '#FC4526',
  },
}
vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    BCard,
    BFormCheckboxGroup,
    AppTour,
    vSelect,
    VueApexCharts,
    BLink,
    BCardHeader,
    BCardBody,
    BButton,
    BCardTitle,
    BButtonGroup,
    BFormRow,
    BFormGroup,
    BImg,
    BCardSubTitle,
    flatPickr,
    BOverlay,
    BRow,
    BBadge,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedAge: ['>18'],
      optionsAges: [
        { text: 'До 18', value: '>18' },
        { text: '19-24', value: '19-24' },
        { text: '24-32', value: '24-32' },
        { text: '32-40', value: '32-40' },
      ],
      selectedGender: ['man'],
      optionsGenders: [
        { text: 'Мужчины', value: 'man' },
        { text: 'Женщины', value: 'women' },
        { text: 'Мужчины + Женщины', value: 'women' },
      ],
      steps: [
        {
          target: '#choose-project',
          header: {
            title: 'Выберите проект',
          },
          content: 'Откройте список для выбора проекта',
        },
      ],
      data: [],
      emptyStats: null,
      emptyGraph: null,
      notFoundProject: false,
      perPage: {
        value: 30,
        text: '30 на странице',
      },
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      campaignFormatLabels: ['кампания', 'кампании', 'кампаний'],
      campaign: [],
      campaignOptions: [],
      chosenCampaigns: [],
      showChartLoading: false,
      changedSeries: [],
      changedChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [{
          labels: {
            formatter(value) {
              return value
            },
          },
        }],
        fill: {
          opacity: 0.3,
          type: 'solid',
        },
        tooltip: {
          offsetY: 20,
        },
        colors: [
          chartColors.area.series3,
          chartColors.area.series2,
          chartColors.area.series1,
          chartColors.area.series4,
          chartColors.area.series5,
          chartColors.area.series6,
          chartColors.area.series7,
          chartColors.area.series8,
          chartColors.area.series9,
          chartColors.area.series10,
        ],
      },
      optionsPerPages: [
        {
          value: 10,
          text: '10 на странице',
        },
        {
          value: 30,
          text: '30 на странице',
        },
        {
          value: 50,
          text: '50 на странице',
        },
        {
          value: 100,
          text: '50 на странице',
        },
        {
          value: 250,
          text: '250 на странице',
        },
        {
          value: 500,
          text: '500 на странице',
        },
      ],
      useTransition: false,
      isLoading: false,
      columnsVisible: {
        campaign: {
          title: 'Кампании',
          display: true,
          subheading: 'Всего:',
          type: 'campaign',
        },
        shows: {
          title: 'Показы',
          display: true,
          type: 'Трафик',
        },
        clicks: {
          title: 'Клики',
          display: true,
          type: 'Трафик',
        },
        consumption: {
          title: 'Расходы',
          display: true,
          type: 'Трафик',
        },
        ctr: {
          title: 'Ctr',
          display: true,
          type: 'Трафик',
        },
        avgCpc: {
          title: 'Средняя стоимость клика',
          display: true,
          type: 'Трафик',
        },
        avgTrafficVolume: {
          title: 'Средний объем трафика',
          display: true,
          type: 'Трафик',
        },
        analyticRevenue: {
          title: 'Выручка (аналитика)',
          display: false,
          type: 'Аналитика',
        },
        analyticMarginProfit: {
          title: 'Маржинальная прибыль (аналитика)',
          display: false,
          type: 'Аналитика',
        },
        analyticGoals: {
          title: 'Кол-во заказов (аналитика)',
          display: false,
          type: 'Аналитика',
        },
        analyticCost: {
          title: 'Стоимость заказов (аналитика)',
          display: false,
          type: 'Аналитика',
        },
        analyticCr: {
          title: 'Cr (аналитика)',
          display: false,
          type: 'Аналитика',
        },
        analyticRoi: {
          title: 'Roi (аналитика)',
          display: false,
          type: 'Аналитика',
        },
        profit: {
          title: 'Выручка (CRM)',
          display: false,
          type: 'Crm',
        },
        marginProfit: {
          title: 'Маржинальная прибыль (CRM)',
          display: false,
          type: 'Crm',
        },
        numberOrders: {
          title: 'Кол-во заказов (CRM)',
          display: false,
          type: 'Crm',
        },
        cost: {
          title: 'Стоимость заказов (CRM)',
          display: false,
          type: 'Crm',
        },
        cr: {
          title: 'Cr (CRM)',
          display: false,
          type: 'Crm',
        },
        roi: {
          title: 'Roi (CRM)',
          display: false,
          type: 'Crm',
        },
      },
      showDetailIcon: true,
      datesTest: [],
      defaultOpenedDetails: [1],
      items: [],
      rangeData: [dateNowPrev, dateNow],
      changedRangeData: [],
      apexChatData,
      saveStatisticData: [],
      checkSortingAttr: null,
      isShowColumnsRow: false,
      dataPickerConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: 'Y-m-d',
      },
    }
  },
  computed: {
    localStorageActiveProject() {
      return localStorage.getItem('active_project_id')
    },
    groupedColumns() {
      function getPredicate() {
        return v => v.type !== 'campaign'
      }

      const columns = _.groupBy(_.filter(this.columnsVisible,
        getPredicate()), 'type')
      return columns
    },
    isChanged() {
      return this.$store.state.project.isChanged
    },
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
    activeProject() {
      return this.$store.state.project.projects.find(c => c.id === this.activeProjectId)
    },
    tableData() {
      return this.$store.state.statistic.tableData
    },
    graphData() {
      return this.$store.state.statistic.graphData
    },
  },
  watch: {
    chosenCampaigns() {
      if (this.chosenCampaigns.length === 0) {
        this.data = JSON.parse(JSON.stringify(this.saveStatisticData))
      } else {
        const campaignNames = this.chosenCampaigns.map(c => c.text)
        this.data = JSON.parse(JSON.stringify(this.saveStatisticData))
          .filter(stat => campaignNames.includes(stat.campaign))
          .filter((x, i, a) => a.indexOf(x) === i)
      }
    },
    activeProjectId(oldState, newState) {
      let isChanged = false
      if (newState == null || oldState !== newState) {
        isChanged = true
      }
      this.loadStats(this.rangeData, isChanged)
    },
  },
  mounted() {
    if (this.activeProjectId) {
      this.loadStats(this.rangeData)
    }
  },
  methods: {
    kFormatter,
    testSignalr() {
      // logout api
      this.$http.get('logger/signal')
      // eslint-disable-next-line no-unused-vars
        .then(response => {
        })
    },
    tourStartAnalytics() {
      this.$tours.test.start()
    },
    isDisabledColumns(val) {
      if (val === 'Crm') {
        return this.activeProject != null ? this.activeProject.isActiveCrm : true
      }
      if (val === 'Трафик') {
        return this.activeProject != null ? this.activeProject.isActiveTraffic : true
      }
      if (val === 'Аналитика') {
        return this.activeProject != null ? this.activeProject.isActiveAnalytic : true
      }
    },
    columnValueFormat(val) {
      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (val === 0) {
        return 0
      }

      if (Math.round(+val) !== +val) {
        return (val.toFixed(2)).toLocaleString()
      }

      return (+val).toLocaleString()
    },
    checkNeedLoadGraph(selectedDates) {
      return (this.changedRangeData[0] !== selectedDates[0]
          || this.changedRangeData[1] !== selectedDates[1])
          && this.changedRangeData != null
    },
    changeDatesRange(selectedDates) {
      const isChangedNeed = this.checkNeedLoadGraph(selectedDates)
      if (isChangedNeed) {
        this.changedSeries = [{
          name: null,
          data: [],
        }]
        this.changedRangeData = selectedDates
        this.loadStats(selectedDates)
      }
    },
    changeGroupByGraph(groupByValue) {
      this.showChartLoading = true
      // this.$store.commit('statistic/CLEAR_GRAPH_DATA')
      const startDate = this.changedRangeData[0]
      const endDate = this.changedRangeData[1]

      const isDate = this.changedRangeData != null
          && startDate != null
          && endDate != null

      if (isDate) {
        this.buildGraph({
          dateGroupBy: groupByValue,
          projectId: this.activeProjectId,
          startDate: (+startDate.yyyymmdd()),
          endDate: (+endDate.yyyymmdd()),
        })
      }
    },
    customSort(a, b, isAsc, val) {
      if (!isAsc) {
        a.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        b.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        return b[val] - a[val]
      }
      a.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      b.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      return a[val] - b[val]
    },
    loadStats(dateRange, isChanged) {
      this.isLoading = true
      this.emptyStats = null
      this.emptyGraph = null
      this.showChartLoading = true
      this.data = []
      this.saveStatisticData = []
      this.campaignOptions = []
      this.changedRangeData = dateRange

      if (dateRange != null) {
        const startDate = dateRange[0]
        const endDate = dateRange[1]

        if (startDate != null && endDate != null) {
          const filterGraphData = {
            dateGroupBy: 0,
            projectId: this.activeProjectId,
            startDate: (+startDate.yyyymmdd()),
            endDate: (+endDate.yyyymmdd()),
          }

          const filterTableData = {
            projectId: this.activeProjectId,
            startDate: (+startDate.yyyymmdd()),
            endDate: (+endDate.yyyymmdd()),
          }

          const isDefaultRange = (+startDate.yyyymmdd()) === (+dateNowPrev.yyyymmdd())
              && (+endDate.yyyymmdd()) === (+dateNow.yyyymmdd())

          const isNeedBuild = this.tableData != null
              && this.graphData != null && isDefaultRange

          if (!isChanged && isNeedBuild) {
            console.log('calculateGraphData')
            this.calculateGraphData(this.graphData, filterGraphData)
            this.calculateTableData(this.tableData)
          } else {
            console.log('buildGraph')
            this.buildGraph(filterGraphData, isDefaultRange)
            this.buildTable(filterTableData, isDefaultRange)
          }

          this.$store.commit('project/UPDATE_LOADING_PROJECT', false)
        }
      }
    },
    calculateGraphData(dataForParse, filterGraphData) {
      const dataForChart = _.values(dataForParse)
      const dates = dataForChart.map(a => new Date(a.date))
      const dataClicksSeries = dataForChart.map(d => d.clicks)
      const dataShowsSeries = dataForChart.map(d => d.shows)
      // const dataNumberOrdersSeries = dataForChart.map(d => d.numberOrders)
      // const dataProfitSeries = dataForChart.map(d => d.profit)
      const dataConsumptionsSeries = dataForChart.map(d => this.columnValueFormat(d.consumption))

      this.changedChartOptions = {
        ...this.changedChartOptions,
        ...{
          xaxis: {
            labels: {
              format: 'dd MMM',
              formatter(value) {
                let formatLocaleDate = {
                  month: 'numeric',
                  day: 'numeric',
                }
                switch (filterGraphData.dateGroupBy) {
                  case 1:
                    formatLocaleDate = {
                      month: 'short',
                    }
                    break
                  case 2:
                    formatLocaleDate = {
                      year: 'numeric',
                    }
                    break
                  default:
                    break
                }

                return new Date(value)
                  .toLocaleDateString('ru', formatLocaleDate)
              },
            },
            categories: dates,
            tickAmount: Math.round(dates.length / 2),
          },
          tooltip: {
            x: {
              formatter(value) {
                let formatLocaleDate = {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                }

                switch (filterGraphData.dateGroupBy) {
                  case 1:
                    formatLocaleDate = {
                      month: 'short',
                    }
                    break
                  case 2:
                    formatLocaleDate = {
                      year: 'numeric',
                    }
                    break
                  default:
                    break
                }

                return new Date(dates[value - 1])
                  .toLocaleDateString('ru', formatLocaleDate)
              },
            },
          },
        },
      }

      const clicksChart = {
        name: this.columnsVisible.clicks.title,
        data: dataClicksSeries,
      }
      const showsChart = {
        name: this.columnsVisible.shows.title,
        data: dataShowsSeries,
      }
      const consumptionsChart = {
        name: `${this.columnsVisible.consumption.title} (руб.)`,
        data: dataConsumptionsSeries,
      }
      /*    const numberOrdersChart = {
            name: `${this.columnsVisible.numberOrders.title}`,
            data: dataNumberOrdersSeries,
          }
          const profitChart = {
            name: `${this.columnsVisible.profit.title}`,
            data: dataProfitSeries,
          } */
      /*     const crChart = {
             name: `${this.columnsVisible.cr.title}`,
             data: dataForChart.map(d => this.columnValueFormat(d.cr)),
           } */
      const avgCpcChart = {
        name: `${this.columnsVisible.avgCpc.title}`,
        data: dataForChart.map(d => this.columnValueFormat(d.avgCpc)),
      }

      this.changedSeries = [
        clicksChart,
        showsChart,
        consumptionsChart,
        /*      numberOrdersChart,
              profitChart,
              crChart, */
        avgCpcChart,
      ]
      this.showChartLoading = false
    },
    buildGraph(filterGraphData, isDefaultRange) {
      this.$store.dispatch('statistic/fetchGraphStatistic', filterGraphData)
        .then(response => {
          // TO-DO: NEED refactoring
          const dataForParse = response.data
          if (dataForParse === 'NO_STATS') {
            this.emptyGraph = dataForParse
          } else {
            if (isDefaultRange) {
              this.$store.commit('statistic/LOAD_GRAPH_DATA', dataForParse)
            }
            this.calculateGraphData(dataForParse, filterGraphData)
          }
        })
    },
    calculateDependedFields(stat) {
      // eslint-disable-next-line radix

      stat.ctr = stat.shows !== 0
        ? ((parseInt(stat.clicks) / parseInt(stat.shows)) * 100) : '-'

      stat.avgCpc = stat.clicks !== 0
        ? ((parseFloat(stat.consumption) / parseInt(stat.clicks))) : '-'

      // calculate CRM values
      stat.roi = stat.consumption !== 0
        ? ((parseFloat(stat.profit) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'

      stat.cr = stat.clicks !== 0
        ? ((parseFloat(stat.numberOrders) / parseInt(stat.clicks)) * 100) : '-'

      stat.cost = stat.numberOrders !== 0
        ? ((parseFloat(stat.consumption) / parseFloat(stat.numberOrders))) : '-'

      // calculate Analytic values
      stat.analyticRoi = stat.consumption !== 0
        ? ((parseFloat(stat.analyticRevenue) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'

      stat.analyticCr = stat.clicks !== 0
        ? ((parseFloat(stat.analyticGoals) / parseInt(stat.clicks)) * 100) : '-'

      stat.analyticCost = stat.analyticGoals !== 0
        ? ((parseFloat(stat.consumption) / parseFloat(stat.analyticGoals))) : '-'
    },
    calculateTableData(dataForParse) {
      // table parsed
      let increment = 0
      Object.keys(dataForParse)
        .forEach(key => {
          const statItem = dataForParse[key]

          const stat = {
            id: 0,
            campaign: key,
            shows: 0,
            clicks: 0,
            consumption: 0,
            avgCpc: 0,
            ctr: 0,
            marginProfit: 0,
            profit: 0,
            cost: 0,
            cr: 0,
            numberOrders: 0,
            analyticRevenue: 0,
            analyticGoals: 0,
            avgTrafficVolume: 0,
            analyticMarginProfit: 0,
            items: [],
          }

          const subAvgTrafficVolumes = []

          statItem.forEach(item => {
            stat.shows += parseInt(item.shows)
            stat.clicks += parseInt(item.clicks)
            stat.consumption += parseFloat(item.consumption)
            stat.numberOrders += parseInt(item.numberOrders)
            subAvgTrafficVolumes.push(parseFloat(item.avgTrafficVolume))
            stat.profit += parseFloat(item.profit)
            stat.analyticRevenue += parseFloat(item.analyticRevenue)
            stat.analyticGoals += parseFloat(item.analyticGoals)
            stat.analyticMarginProfit = stat.analyticRevenue - stat.consumption
            stat.marginProfit = stat.profit - stat.consumption

            const subObj = {
              // eslint-disable-next-line no-plusplus
              id: `stat${increment++}`,
              criterion: item.criterion,
              shows: item.shows,
              clicks: item.clicks,
              consumption: item.consumption,
              ctr: 0,
              avgCpc: 0,
              profit: item.profit,
              marginProfit: item.marginProfit,
              numberOrders: item.numberOrders,
              analyticRevenue: item.analyticRevenue,
              analyticGoals: item.analyticGoals,
              analyticMarginProfit: item.analyticMarginProfit,
              avgTrafficVolume: item.avgTrafficVolume,
              // calculated crm values
              cr: 0,
              roi: 0,
              cost: 0,
              // calculated analytic values
              analyticRoi: 0,
              analyticCr: 0,
              analyticCost: 0,
            }

            this.calculateDependedFields(subObj)
            stat.items.push(subObj)
          })

          function calculateAverage(array) {
            let total = 0
            let count = 0

            array.forEach((item, index) => {
              total += item
              count++
            })

            return total / count
          }

          stat.avgTrafficVolume = calculateAverage(subAvgTrafficVolumes)

          // set the rule how to calculate analytic and crm
          this.calculateDependedFields(stat)

          // eslint-disable-next-line no-plusplus
          stat.id = `stat${increment++}`
          this.data.push(stat)
          this.saveStatisticData.push(stat)
        })

      let indexCampaign = 0
      this.data.map(c => c.campaign)
        .forEach(campaignText => {
          indexCampaign += 1
          this.campaignOptions.push({
            value: indexCampaign,
            text: campaignText,
          })
        })
      this.isLoading = false
    },
    buildTable(filterTableData, isDefaultRange) {
      this.$store.dispatch('statistic/fetchTableStatistic', filterTableData)
        .then(response => {
          // TO-DO: NEED refactoring
          const dataForParse = response.data
          if (dataForParse === 'NO_STATS') {
            this.emptyStats = dataForParse
          } else {
            if (isDefaultRange) {
              this.$store.commit('statistic/LOAD_TABLE_DATA', dataForParse)
            }
            this.calculateTableData(dataForParse)
          }
        })
        .catch(() => {
          this.notFoundProject = true
        })
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    // eslint-disable-next-line consistent-return
    transitionName() {
      if (this.useTransition) {
        return 'fade'
      }
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';
</style>
<style>
.flatpickr-input {
  width: 210px !important;
}

</style>
